import React from 'react';
import {MDBCarousel, MDBCarouselItem} from 'mdb-react-ui-kit';
import immagine1 from '../assets/Carousel01.png'
import immagine2 from '../assets/Carousel02.png'
import immagine3 from '../assets/Carousel03.png'


export default function App() {
  return (
     <MDBCarousel id='navCarousel'> 
      <MDBCarouselItem
        className='carItem' 
        itemId={1}
        src={immagine1}
        alt='...'
      />       
      <MDBCarouselItem
        className='carItem'
        itemId={2}
        src={immagine2}
        alt='...'
      />   
      <MDBCarouselItem
        className='carItem'
        itemId={3}
        src={immagine3}
        alt='...'
      />     
    </MDBCarousel>
  );
}