import React, { useState } from "react";
import Sara from "../assets/Saraz.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import icoImg from "../assets/icoElenco2.png";
import Modale from "./Modale";
import Collapse from "react-bootstrap/Collapse";


const Main = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const handleModalShow = (e) => {
    setModalShow(!modalShow);
    if (e) {
      const sez = e.target.id;
      localStorage.setItem("sez", sez);
    }
  };

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <Modale show={modalShow} onHide={handleModalShow} />
      <div id="container" className="d-flex flex-column">
        <Container>
          <Row className="my-3">
            <Col className="d-flex justify-content-center align-items-center my-4">
              
                <img src={Sara} id="sara" className="my-0" />
              
            </Col>
            <Col className="d-flex flex-column justify-content-center">
              <hr className="myBorder"></hr>
              <div id="info" className="m-1">
                <ul>
                  <li
                    onClick={handleMenu}
                    className="d-flex align-items-center my-3"
                  >
                    <img src={icoImg} />
                    <h3 className="m-0 mt-1 ms-2 px-3 pt-1">Massoterapia</h3>
                  </li>
                  <Collapse in={openMenu}>
                    <ul>
                      <li className="fs-4 ms-4 ps-3 d-flex">
                        <div
                          onClick={handleModalShow}
                          id="1"
                          className="over px-3"
                        >
                          Massaggio Decontratturante
                        </div>
                      </li>
                      <li className="fs-4 ms-4 ps-3 d-flex">
                        <div
                          onClick={handleModalShow}
                          id="2"
                          className="over px-3"
                        >
                          Massaggio Linfodrenante
                        </div>
                      </li>
                      <li className="fs-4 ms-4 ps-3 d-flex">
                        <div
                          onClick={handleModalShow}
                          id="3"
                          className="over px-3"
                        >
                          Massaggio Miorilassante
                        </div>
                      </li>
                    </ul>
                  </Collapse>
                  <li className="d-flex align-items-center my-3">
                    <img src={icoImg} />
                    <h3
                      onClick={handleModalShow}
                      id="4"
                      className="m-0 mt-1 ms-2 px-3 pt-1"
                    >
                      Trattamento Craniosacrale
                    </h3>
                  </li>
                  <li className="d-flex align-items-center my-3">
                    <img src={icoImg} />
                    <h3
                      onClick={handleModalShow}
                      id="5"
                      className="m-0 mt-1 ms-2 px-3 pt-1"
                    >
                      Bendaggio Funzionale
                    </h3>
                  </li>
                  <li className="d-flex align-items-center my-3">
                    <img src={icoImg} />
                    <h3
                      onClick={handleModalShow}
                      id="6"
                      className="m-0 mt-1 ms-2 px-3 pt-1"
                    >
                      Kinesio Taping
                    </h3>
                  </li>
                </ul>
              </div>
              <hr className="myBorder"></hr>
            </Col>
          </Row>
        </Container>

        <Container className="textFormat">
          <Row className="my-3">
            <Col xs={12} md={6} className="my-2 p-5">
              <h4>Chi è il massoterapista MCB</h4>
              <p className="fs-4">
                Il Massaggiatore Capo Bagnino degli Stabilimenti Idroterapici
                (Massaggiatore Terapista e Idroterapista) è abilitato
                all'esercizio dell'Arte Ausiliaria delle Professioni Sanitarie:
                dietro prescizione medica per le prestazioni terapeutiche e in
                piena autonomia per le pratiche di massaggio benessere e
                sportivo.<br/>
                <span className="fs-6">(Art.1 R.D. n°1334 del 31/05/1928 - Art.99 R.D. n°1265 del 27/07/1934 - Art.1 D.P.R. n°10 del 15/01/1972)</span>

              </p>
            </Col>
            <Col xs={12} md={6} className="my-2 p-5">
              <h4>Cosa è la Massoterapia</h4>
              <p className="fs-4">
                La massoterapia comprende varie tecniche di massaggio, aventi lo
                scopo di promuovere la salute e il benessere di diversi apparati
                del corpo umano, tra cui principalmente l'apparato
                muscolo-scheletrico.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Main;
