import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import '../style/modale.css';

const Modale = (show) => {
  const sez = localStorage.getItem("sez");
  //console.log(sez);

  return (
    <Modal
      {...show}
      size="lg"
      aria-labelledby="modalTitle"
      centered
      className="myModal"
    >           
      <Modal.Header className="modalHeader">        
        <Modal.Title id="modalTitle">
            {sez === "1" && (<h2>Massaggio Decontratturante</h2>)}
            {sez === "2" && (<h2>Massaggio Linfodrenante</h2>)}
            {sez === "3" && (<h2>Massaggio Miorillassante</h2>)}
            {sez === "4" && (<h2>Trattamento Cranio-Sacrale</h2>)}
            {sez === "5" && (<h2>Bendaggio Funzionale</h2>)}
            {sez === "6" && (<h2>Kinesio Taping</h2>)}
        </Modal.Title>        
      </Modal.Header>      
      <Modal.Body className="modalBody">                
        {sez === "1" && (
          <p className="fs-4">
            Il massaggio decontratturante è un tipo di massaggio il cui scopo è
            quello di sciogliere le contratture muscolari; esse sono
            contrazioni involontarie insistenti e dolorose di uno o più muscoli
            scheletrici che si presentano rigidi con ipertonia delle fibre
            muscolari apprezzabili al tatto. Gli obiettivi che si vogliono
            raggiungere sono allentare le tensioni muscolari tipiche di questo
            disturbo, ridurre il dolore, velocizzare i tempi di recupero; viene
            inoltre favorita le circolazione sanguigna e linfatica.
          </p>
        )}
        {sez === "2" && (
          <p className="fs-4">
            Il massaggio linfodrenante è un particolare tipo di massaggio il cui
            scopo è quello di favorire il drenaggio dei fluidi linfatici
            riducendone i ristagni attraverso la stimolazione della circolazione
            linfatica. Il principale beneficio è contrastare il gonfiore e l'edema
            che tipicamente si manifestano quando la circolazione linfatica non
            è ottimale. Utilizzato frequentemente anche come trattamento post-infortunio per
            esempio dopo distorsioni di caviglia, ginocchio o anche nel post-intervento
            chirurgico laddove si presentano edema e/o gonfiore.
          </p>
        )}
        {sez === "3" && (
          <p className="fs-4">
            Questa tipologia di massaggio racchiude e fonde diverse manualità
            che hanno origine da tecniche di massaggio finalizzate al
            rilassamento sia dei tessuti superficiali, sia di quelli profondi. Gli
            obiettivi sono: il raggiungimento di uno stato di rilassamento
            psicofisico con conseguente attenuazione di tensioni fisiche ed
            emotive, l'abbassamento dello stress, una migliore circolazione di tutti i
            liquidi corporei, una migliore respirazione ed una migliore percezione del proprio
            corpo.
          </p>
        )}
        {sez === "4" && (
          <p className="fs-4">
            Il trattamento cranio-sacrale è una tecnica olistica di natura
            osteopatica, definita anche disciplina eziopatica, ovvero focalizzata
            sulla ricerca delle cause scatenanti i disturbi manifestati.
            Mediante leggere manipolazioni, quasi impercettibili per il
            paziente, l’operatore interagisce con l’impulso ritmico craniale;
            quest'ultimo è una componente fisiologica che, grazie al liquor cefalorachidiano,
            comunica con il sistema nervoso centrale; questo trattamento agisce profondamente sul
            sistema nervoso, influenzando il sistema ormonale e quello
            immunitario, favorendo l’armonia degli stati psicologici ed emotivi,
            stimolando uno stato di benessere. Il trattamento cranio-sacrale è
            delicato e sicuro e viene spesso consigliato in pazienti con stati
            considerati "rischiosi" come donne in gravidanza oppure persone in stato 
            post-operatorio o che stanno affrontanto fasi di riabilitazione dopo un incidente.
          </p>
        )}
        {sez === "5" && (
          <p className="fs-4">
            Il bendaggio funzionale è una tecnica di immobilizzazione parziale
            volta a ridurre i tempi di guarigione rispetto alle metodiche di
            immobilizzazione tradizionali. Un'articolazione viene infatti messa
            in scarico e protetta, soltanto nella direzione di movimento
            dolorosa o patologica.
          </p>
        )}
        {sez === "6" && (
          <p className="fs-4">
            Il Taping neuro-muscolare è una tecnica che consiste
            nell’applicazione di un nastro adesivo elastico (tape) sulla cute,
            con effetto diretto locale e a distanza per via riflessa. Obiettivi
            principali:
            <br />
            -alleviare il dolore
            <br />
            -normalizzare la tensione muscolare
            <br />
            -migliorare la vascolararizzazione sanguinea
            <br />
            -rimuovere la congestione vensosa e linfatica
          </p>
        )}        
      </Modal.Body>       
      <Modal.Footer>
        <Button onClick={show.onHide} className="modalButton">Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modale;
