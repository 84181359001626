import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  //MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';
import { useWindowSize } from "@uidotdev/usehooks";
//import NavDropdown from 'react-bootstrap/NavDropdown';

function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}

export default function Navigation() {
  const [showNavSecond, setShowNavSecond] = useState(false);
  const [brandType, setBrandType] = useState("1")
  const size = useWindowSize();
  
  
  

  return (
    <MDBNavbar className="myNavBar" expand="lg">
      {" "}
      {/*light bgColor='light'*/}
      <MDBContainer fluid className="px-3">
        {size.width >= 700 && <MDBNavbarBrand href="#">
           <span id="navBrand">Sara Zanotti</span>  
        </MDBNavbarBrand>} 
        {size.width < 700 && 
        <MDBNavbarBrand href="#">
          <div className='d-flex flex-column'>
           <span id="navBrand">Sara</span> 
           <span id="navBrand2">Zanotti</span>  
          </div>  
        </MDBNavbarBrand>
        }
        {/* <MDBNavbarToggler
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNavSecond(!showNavSecond)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler> */}
        <MDBCollapse navbar show={showNavSecond}>
          {/* <MDBNavbarNav className="navMenu">
            <MDBNavbarLink className="navMenuText mx-2 px-4" href="#">
              Home
            </MDBNavbarLink>
            <MDBNavbarLink className="navMenuText mx-2 px-4" href="#">
              Contatti
            </MDBNavbarLink>            
          
          <NavDropdown className="navMenuText mx-2 px-4" title="Trattamenti" id="basic-nav-dropdown">
            <NavDropdown.Item href="#" className="navMenuText">
              trattamento1
            </NavDropdown.Item>
            <NavDropdown.Item href="#" className="navMenuText">
              trattamento2
            </NavDropdown.Item>
            <NavDropdown.Item href="#" className="navMenuText">
              trattamento3
            </NavDropdown.Item>
            <NavDropdown.Item href="#" className="navMenuText">
              trattamento4
            </NavDropdown.Item>
          </NavDropdown>
          </MDBNavbarNav> */}
        </MDBCollapse>
      </MDBContainer>
      <Browser size={size} />
    </MDBNavbar>
    
  );
}


