import React from 'react';
import { MDBFooter, MDBContainer, MDBCol, MDBRow, MDBBtn, MDBInput, MDBIcon} from 'mdb-react-ui-kit';
import '../style/footer.css'
import Mappa from '../components/Mappa'

export default function App() {
  return (
    <MDBFooter className='text-center text-lg-left textColor bgBlue py-3'>
      <MDBContainer className='p-4 pb-0'>        
          <MDBRow className='d-flex justify-content-around'>
            <MDBCol size='auto' className='p-2'>
              <p className='pt-2 d-flex flex-column align-items-start'>
                <strong className='mb-2 fs-4'>Contatti:</strong>
                <p className='my-1 fs-5'>Tel: 345-9673083</p>
                <p className='my-1 fs-5'><a className='myLink' href = "mailto:sarazano87@gmail.com">email: sarazano87@gmail.com</a></p>
              </p>
            </MDBCol> 
            <MDBCol size='auto' className='p-2'>
              <p className='pt-2 d-flex flex-column align-items-start'>
                <strong className='mb-2 fs-4'>Si riceve presso:</strong>
                <p className='my-1 fs-5'>Studio Seva</p>
                <p className='my-1 fs-5'>via S.Pietro, n°10</p>
                <p className='my-1 fs-5'>24022, Alzano Lombardo - BG</p>
                <p className='my-1 fs-5'>P.I. = 04604060162</p>
                
              </p>
            </MDBCol> 
          </MDBRow>

          {/* https://www.google.it/maps/dir//Studio+Seva,+Via+San+Pietro,+10,+24022+Alzano+lombardo+BG/@45.7367766,9.7588365,13.5z */}
          <MDBRow className='d-flex justify-content-center'>
            <p><strong className='mb-2 fs-4'>Come Raggiungerci</strong></p>
          </MDBRow>
          <MDBRow className='d-flex justify-content-center'>
            <Mappa/>
          </MDBRow>          
          <MDBBtn className='m-4' style={{ backgroundColor: '#3b5998' }} target='blank' href='https://www.facebook.com/profile.php?id=100094321013776'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>
      </MDBContainer>

      <div className='text-center p-3'>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-dark' href='#'>
          Sara Zanotti
        </a>
      </div>
    </MDBFooter>
  );
}