import React from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { Container , Row , Col } from 'react-bootstrap'
import "@maptiler/sdk/dist/maptiler-sdk.css";
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';
import '../style/map.css';

const Map = () => {
    
  const point = [45.73437, 9.72847]
  const zoomLevel = 18;
  const customIcon = new Icon({
    iconUrl: "/logo192.png",
    iconSize: [50, 30],
    iconAnchor: [-15, 1],
    popupAnchor: [30, -0]
  })

  return (
    <Container className='generalMapContainer'>      
      <div className='test'><a className='fs-5' target="_blank" href='https://www.google.it/maps/dir//Studio+Seva,+Via+San+Pietro,+10,+24022+Alzano+lombardo+BG/@45.7367766,9.7588365,13.5z'>vai su Google Maps</a></div>
      <MapContainer center={point} zoom={zoomLevel} scrollWheelZoom={false}>      
      <TileLayer
            attribution='&copy; <a href="https://api.maptiler.com/maps/basic-v2/?key=pvvpnuTQmgbSUoguRTAT#17.8/45.73425/9.72843'
            url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=pvvpnuTQmgbSUoguRTAT"
          />
          <Marker position={point} icon={customIcon}>
            <Popup>
              Studio Seva<br/>via S.Pietro n°10<br/>Alzano Lombardo (BG)
            </Popup>
          </Marker>
      </MapContainer>
    </Container>
  )
}

export default Map