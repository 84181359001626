import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import foto01 from "../assets/01.png";
import foto02 from "../assets/02.png";
import foto03 from "../assets/03.png";
import foto04 from "../assets/04.png";
import foto05 from "../assets/05.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../style/studioGallery.css";


const StudioGallery = () => {
  var settings = {
    dots: true,
    infinite: true, 
    autoplaySpeed: 3000,
    autoplay: true,    
    centerMode: true,
    fade: true    
  }

  return (
    <>
    
    <div className="studioGalleryBG">
    <Container className="slider">
      <h4>Studio Seva: gallery</h4>
      <Slider  {...settings}>        
          <img className="sliderImg" src={foto01}/>        
          <img className="sliderImg" src={foto02}/>        
          <img className="sliderImg" src={foto03}/>        
          <img className="sliderImg" src={foto04}/>        
          <img className="sliderImg" src={foto05}/>
      </Slider>
    </Container> 
    </div>
    </>
  );
};

export default StudioGallery;
