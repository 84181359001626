import React from 'react'
import Navigation from '../components/Navigation'
import NavCarousel from '../components/NavCarousel'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Studio from '../components/StudioGallery'

const Home = () => {
  return (
    <>
        <Navigation/>
        <NavCarousel className='NavCarousel'/>
        <Main/>
        <Studio/>      
        <Footer/>
    </>
  )
}

export default Home